import * as React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import { useRecordContext } from 'ra-core';

import { sanitizeFieldRestProps } from 'react-admin';

const useStyles = makeStyles(
    {
        list: {
            display: 'flex',
            listStyleType: 'none',
        },
        image: {
            margin: '0.5rem',
            maxHeight: '10rem',
        },
    },
    { name: 'RaCustomImageField' }
);

const CustomImageField = (props) => {
    const baseImageUrl = process.env.REACT_APP_CDN_BASE_URL;
    const {
        className,
        classes: classesOverride,
        emptyText,
        source,
        src,
        title,
        ...rest
    } = props;
    let record = useRecordContext(props);
    let sourceValue = get(record, source);
    if (props.record && ! props.record.rawFile) {
        record = props.record;
        if (get(record, source) != null) {
            sourceValue = baseImageUrl + get(record, source);       
        }
    }
    const classes = useStyles(props);
    if (!sourceValue) {
        return emptyText ? (
            <Typography
                component="span"
                variant="body2"
                className={className}
                {...sanitizeFieldRestProps(rest)}
            >
                {emptyText}
            </Typography>
        ) : (
            <div className={className} {...sanitizeFieldRestProps(rest)} />
        );
    }

    const titleValue = get(record, title) || title;

    return (
        <div className={className} {...sanitizeFieldRestProps(rest)}>
            <img
                title={titleValue}
                alt={titleValue}
                src={sourceValue}
                className={classes.image}
            />
        </div>
    );
};

// What? TypeScript loses the displayName if we don't set it explicitly
CustomImageField.displayName = 'CustomImageField';

CustomImageField.defaultProps = {
    addLabel: false,
};

export default CustomImageField;