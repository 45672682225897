// in posts.js
import * as React from "react";
import { List, Datagrid, TextField, ShowButton, Filter, TextInput, Pagination, TopToolbar, CreateButton, DeleteButton, EditButton } from 'react-admin';

const PrefecturePagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const PrefectureActions = (props) => (
    <TopToolbar>
        <CreateButton/>
    </TopToolbar>
);

const PrefecturesFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Identifiant" source="id" alwaysOn />
        <TextInput label="Ville" source="city" alwaysOn />
        <TextInput label="Address" source="address" alwaysOn />
    </Filter>
);

export const PrefecturesList = (props) => (
    <List {...props} pagination={<PrefecturePagination />} title="Préfectures" actions={<PrefectureActions />} filters={<PrefecturesFilter />}>
        <>
            <Datagrid>
                <TextField label="Identifiant" source="id" />
                <TextField label="Ville" source="city" sortable={false}/>
                <TextField label="Addresse" source="address" sortable={false}/>
                <EditButton />
                <DeleteButton />
            </Datagrid>
        </>
    </List>
);