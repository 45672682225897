import React, { useEffect, useState } from 'react';
import { CartesianGrid, XAxis, YAxis, Tooltip, BarChart, LineChart, Bar, Line, Legend, Label, ResponsiveContainer } from 'recharts';
import { useDataProvider } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';


const OriginGraph = () => {

    const dataProvider = useDataProvider();
    const [value, setValue] = useState('');
    const [graphData, setGraphData] = useState([]);

    useEffect(() => {
        refreshGraph();
    }, [value]);


    const refreshGraph = async (days) => {
        const subscriptionPromise = await dataProvider.getCustom('Admin/Origin/Stats', { days });
        setGraphData(subscriptionPromise.data);
    };

    return (
        <>
            <div style={{ width: "100%", height: "100%" }}>
                <Grid container direction="row" justify="flex-start" alignItems="center">
                    <Button variant="contained" color="primary" onClick={() => refreshGraph(7)} >
                        7j
                </Button>
                    <Button variant="contained" color="primary" onClick={() => refreshGraph(30)} style={{ marginLeft: "15px" }}>
                        30 derniers jours
                </Button>
                    <Button variant="contained" color="primary" onClick={() => refreshGraph()} style={{ marginLeft: "15px" }}>
                        Tout
                </Button>
                </Grid>
                <ResponsiveContainer width='100%' height='80%' style={{ top: "15px" }}>
                    <LineChart data={graphData} margin={{ top: 15, right: 30, left: 20, bottom: 20 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" >
                            <Label value="Date" offset={0} position="bottom" />
                        </XAxis>
                        <YAxis >
                            <Label value="SEO/SEA" angle="-90" position="insideLeft" offset={20} startOffset={20} />
                        </YAxis>
                        <Tooltip />
                        <Legend verticalAlign="top" height={36} />
                        <Line name="SEO" type="monotone" dataKey="seo" stroke="#02DD03" />
                        <Line name="SEA" type="monotone" dataKey="sea" stroke="#FF4C36" />
                    </LineChart >
                </ResponsiveContainer>
            </div>
        </>
    );
}

export default OriginGraph