import * as React from "react";
import { Edit, SimpleForm, TextInput, required } from 'react-admin';

export const ServicePrefectureEdit = (props) => (
    <Edit {...props}>
        <SimpleForm redirect="list">
            <TextInput disabled label="Identifiant" source="id" />
            <TextInput label="Nom" source="name" validate={required()} />
            <TextInput label="Description" source="description" />
        </SimpleForm>
    </Edit>
);