import jwt_decode from "jwt-decode";


const authProvider = {
    login: ({ username, password }) => {
        const jsonBody = {
            email: username,
            password: password
        }

        const request = new Request(process.env.REACT_APP_URL_BACK + '/Authenticate/Login', {
            method: 'POST',
            body: JSON.stringify(jsonBody),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json().then(res => {
                    var decoded = jwt_decode(res.token);
                    localStorage.setItem('auth', JSON.stringify(res));
                    localStorage.setItem('permissions', decoded.role);
                });
            })
            .catch((e) => {
                throw new Error(e.message);
            });
    },
    checkError: (error) => {
        const status = error.status;
        console.log(error)
        if (status === 401 || status === 403) {
            localStorage.removeItem('auth');
            return Promise.reject({ message: false });
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    checkAuth: () => {
        const authContent = localStorage.getItem('auth');
        if (authContent == null) {
            return Promise.reject();
        }
        const auth = JSON.parse(authContent);
        if (auth.token == null) {
            localStorage.removeItem('auth');
            return Promise.reject();
        }

        var decoded = jwt_decode(auth.token);
        const expirationDate = new Date((decoded.exp) * 1000);
        if (expirationDate < new Date()) {
            localStorage.removeItem('auth');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    getIdentity: () => {
        try {
            const { id, fullName, avatar } = JSON.parse(localStorage.getItem('auth'));
            return Promise.resolve({ id, fullName, avatar });
        } catch (error) {
            return Promise.reject(error);
        }
    },
    // authorization
    getPermissions: () => {
        const role = localStorage.getItem('permissions');
        return role != null ? Promise.resolve(role) : Promise.reject();
    },
    logout: () => {
        localStorage.removeItem('auth');
        localStorage.removeItem('permissions');
        return Promise.resolve();
    },
};

export default authProvider;