// in posts.js
import * as React from "react";
import { List, Datagrid, TextField, ShowButton, Filter, TextInput, Pagination, TopToolbar, CreateButton, DeleteButton, EditButton } from 'react-admin';

const MotifPrefecturePagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const MotifPrefectureActions = (props) => (
    <TopToolbar>
        <CreateButton />
    </TopToolbar>
);

const MotifPrefecturesFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Identifiant" source="id" alwaysOn />
        <TextInput label="Nom" source="name" alwaysOn />
        <TextInput label="Description" source="description" alwaysOn />
    </Filter>
);

export const MotifPrefecturesList = (props) => (
    <List {...props} pagination={<MotifPrefecturePagination />} title="Motifs Préfectures" actions={<MotifPrefectureActions />} filters={<MotifPrefecturesFilter />}>
        <Datagrid>
            <TextField label="Identifiant" source="id" />
            <TextField label="Nom" source="name" sortable={false} />
            <TextField label="Description" source="description" sortable={false} />
            <EditButton />
            <DeleteButton />
        </Datagrid>

    </List>
);