import * as React from "react";
import { Route } from 'react-router-dom';
import LTVGraph from "./Pages/LTVGraph";
import OriginGraph from "./Pages/OriginGraph";
import SubscriptionsGraph from './Pages/SubscriptionsGraph';


export default [
    <Route exact path="/subscriptions" component={SubscriptionsGraph} />,
    <Route exact path="/origins" component={OriginGraph} />,
    <Route exact path="/ltv" component={LTVGraph} />,
];