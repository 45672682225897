import React from "react";
import { Edit, TabbedForm, FormTab, TextInput, ImageInput, ReferenceInput, ArrayInput, AutocompleteInput, SimpleFormIterator, FormDataConsumer, required, Labeled, TextField, BooleanInput } from 'react-admin';
import Box from '@material-ui/core/Box';
import RichTextInput from 'ra-input-rich-text';
import CustomImageField from "../../Components/CustomImageField";
import { goToWebSite, transform } from "./AppointmentUtil";
import { GenericToolbar, useArticleStyles } from "../GenericStyle";
import Button from '@material-ui/core/Button';
import { useDataProvider } from 'react-admin';

export const AppointmentEdit = (props) => {
    const classes = useArticleStyles(props);
    const dataProvider = useDataProvider();

    return (
        <Edit {...props} transform={transform}>
            <TabbedForm toolbar={<GenericToolbar />}>
                <FormTab label="Général">
                    <Box sx={{ width: "100%!important" }}>
                        <TextInput disabled label="Id" source="id" />
                        <BooleanInput label="Rendez vous dématérilisé" source="isDematerialised" />
                        <BooleanInput label="Rendez vous Orion" source="isOrion" />
                        <BooleanInput label="Utilise l'ancien système de prise de rendez-vous" source="isOld" />
                        <BooleanInput label="La personne doit se rendre forcement en préfecture" source="isPresentialOnly" />
                        <BooleanInput label="La prise de rendez vous nécessite une connexion FranceConnect" source="isFranceConnect" />
                        <TextInput source="appointmentURL" label="URL Rendez Vous" validate={required()} />
                        <ReferenceInput label="Préfecture" source="prefecture.id" perPage={500} reference="Prefectures" validate={required()}>
                            <AutocompleteInput optionText="city" />
                        </ReferenceInput>
                        <ReferenceInput label="Motif" source="motifPrefecture.id" perPage={500} reference="MotifPrefectures" validate={required()}>
                            <AutocompleteInput optionText="name" />
                        </ReferenceInput>
                        <ReferenceInput label="Service" source="servicePrefecture.id" perPage={500} reference="ServicePrefectures" validate={required()}>
                            <AutocompleteInput optionText="name" />
                        </ReferenceInput>
                        {/* <ArrayInput source="urlToCrawls" label="URLs à crawler">
                            <SimpleFormIterator>
                                <TextInput hidden disabled label="Id" source="id" />
                                <TextInput label="Nom de l'url" source="name" />
                                <TextInput label="URL" source="url" />
                            </SimpleFormIterator>
                        </ArrayInput> */}
                    </Box>
                </FormTab>
                <FormTab label="SEO">
                    <FormDataConsumer>
                        {({ formData, dispatch, ...rest }) => {
                            return (
                                <div>
                                    <Button variant="contained" className={classes.goButton} onClick={() => goToWebSite(formData, dataProvider)}>Voir sur le site</Button>
                                </div>
                            )
                        }}
                    </FormDataConsumer>
                    <TextInput source="titleText" label="Titre de l'article" />
                    <TextInput source="article" label="Article" multiline={true} className={classes.article} fullWidth={true} />
                    <TextInput source="metaTitle" label="Meta Title" />
                    <TextInput source="metaDescription" label="Meta Description" formClassName={classes.description} />
                    <TextInput source="articleAltTextImage" label="Alt Image article" />

                    <ImageInput source="articleImage" label="Image article" multiple={false} accept="image/*" >
                        <CustomImageField source="articleSrcImage" title="title" />
                    </ImageInput>
                    <FormDataConsumer>
                        {({ formData, dispatch, ...rest }) => {
                            if (!formData.articleImage) {
                                return (
                                    <div>
                                        <Labeled label="Original article image">
                                            <CustomImageField record={formData} source="articleSrcImage" title="title" />
                                        </Labeled>
                                    </div>
                                );
                            }
                        }}
                    </FormDataConsumer>
                </FormTab>
            </TabbedForm>
        </Edit>);
};
