import * as React from "react";
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';

const CustomDateField = (props) => {
    const { source } = props;
    const record = useRecordContext(props);
    return <span>{new Date(record[source] + "Z").toLocaleString()}</span>;
}

CustomDateField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default CustomDateField;