// in posts.js
import * as React from "react";
import { List, Datagrid, TextField, ReferenceField, useDataProvider, useRefresh, showNotification } from 'react-admin';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';


const useSuccessStyles = makeStyles(
    {
        successButton: {
            color: 'green'
        },
        loading: {
            width: '20px !important',
            height: '20px !important',
            color: 'rgba(0, 0, 0, 0.87)'
        }
    }
);

const useFailStyles = makeStyles(
    {
        failButton: {
            color: 'red'
        },
        loading: {
            width: '20px !important',
            height: '20px !important',
            color: 'rgba(0, 0, 0, 0.87)'
        }
    }
);

const ApproveButton = ({ record }) => {
    const classes = useSuccessStyles();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();
    const [isLoading, setLoading] = React.useState(false);

    function approve() {
        setLoading(true);
        dataProvider.customUpdate(`Appointments/ManualCheck/${record.id}`, { data: { AppointmentId: record.id, MeetingAvailable: true } }).then(res => {
            setLoading(false);
            refresh();
        });
    }
    return <Button variant="outlined" onClick={() => approve()} startIcon={<CheckCircleIcon />} className={classes.successButton} >Disponible</Button>
};

const DeclineButton = ({ record }) => {
    const classes = useFailStyles();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();
    const [isLoading, setLoading] = React.useState(false);

    function decline() {
        setLoading(true);
        dataProvider.customUpdate(`Appointments/ManualCheck/${record.id}`, { data: { AppointmentId: record.id, MeetingAvailable: false } }).then(res => {
            setLoading(false);
            refresh();
        });
    }
    return (
        <Button variant="outlined" disabled={isLoading} onClick={() => decline()} startIcon={isLoading ? <CircularProgress className={classes.loading} /> : <CancelIcon />} className={classes.failButton}>Pas disponible</Button>
    );
};

const SeeMeeting = ({ record }) => {
    function goToMeeting(url) {
        window.open(url, "_blank")
    }
    return <Button variant="outlined" onClick={() => goToMeeting(record.appointmentURL)} startIcon={<OpenInNewIcon />}>Ouvrir</Button>;
};

export const AppointmentsToCheckList = (props) => (
    <List {...props} title="Préfectures" >
        <>
            <Datagrid>
                <TextField label="Identifiant" source="id" />
                <ReferenceField label="Prefecture" source="prefecture.id" reference="Prefectures">
                    <TextField source="city" />
                </ReferenceField>
                <ReferenceField label="Motif" source="motifPrefecture.id" reference="MotifPrefectures">
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField label="Service" source="servicePrefecture.id" reference="ServicePrefectures">
                    <TextField source="name" />
                </ReferenceField>
                <SeeMeeting />
                <DeclineButton />
                <ApproveButton />
            </Datagrid>
        </>
    </List>
);