import * as React from "react";
import { Edit, SimpleForm, TextInput, required, SelectInput } from 'react-admin';

export const UserEdit = (props) => (
    <Edit {...props}>
        <SimpleForm redirect="list">
            <TextInput disabled label="Identifiant" source="id" />
            <TextInput label="Nom" source="lastName" validate={required()} />
            <TextInput label="Prénom" source="firstName" validate={required()} />
            <TextInput disabled label="Email" source="email" validate={required()} />
            <SelectInput source="role" choices={[
                { id: 'SuperAdmin', name: 'SuperAdmin' },
                { id: 'Admin', name: 'Admin' },
                { id: 'SEO', name: 'SEO' },
            ]} />

        </SimpleForm>
    </Edit>
);