// in posts.js
import * as React from "react";
import { List, Datagrid, TextField, ReferenceField, ShowButton, Filter, TextInput, Pagination, TopToolbar, CreateButton, DeleteButton, EditButton, BooleanField } from 'react-admin';

const AppointmentPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const AppointmentActions = (props) => (
    <TopToolbar>
        <CreateButton />
    </TopToolbar>
);

const AppointmentsFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Identifiant" source="id" alwaysOn />
        <TextInput label="Ville préfecture" source="city" alwaysOn />
        <TextInput label="Nom du motif" source="motif" alwaysOn />
        <TextInput label="Nom du service" source="service" alwaysOn />
    </Filter>
);

export const AppointmentsList = (props) => (
    <List {...props} pagination={<AppointmentPagination />} title="Préfectures" actions={<AppointmentActions />} filters={<AppointmentsFilter />}>
        <>
            <Datagrid>
                <TextField label="Identifiant" source="id" />
                <ReferenceField label="Prefecture" source="prefecture.id" reference="Prefectures">
                    <TextField source="city" />
                </ReferenceField>
                <ReferenceField label="Motif" source="motifPrefecture.id" reference="MotifPrefectures">
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField label="Service" source="servicePrefecture.id" reference="ServicePrefectures">
                    <TextField source="name" />
                </ReferenceField>
                <BooleanField source="isDematerialised" label="Dématérialisé" />
                <BooleanField source="isOrion" label="Orion" />
                <EditButton />
                <DeleteButton />
            </Datagrid>
        </>
    </List>
);