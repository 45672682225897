
import { convertFileToBase64 } from "../../Providers/DataProvider";

export const transform = async (data) => {
    var cityURL = data.city.trim().toLowerCase();
    cityURL = cityURL.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    cityURL = cityURL.replaceAll("'", "-");
    cityURL = cityURL.replaceAll(" ", "-");
    
    if (data.articleImage) {

        const extension = data.articleImage.rawFile.path.split(".")[1];
        var base64Picture = await convertFileToBase64(data.articleImage)
        data.articleImage = {
            src: base64Picture,
            filename: `${cityURL}.${extension}`,
        }
    }
    if (data.thumbImage) {
        const extension = data.thumbImage.rawFile.path.split(".")[1];
        var base64Picture = await convertFileToBase64(data.thumbImage)
        data.thumbImage = {
            src: base64Picture,
            filename: `thumb-${cityURL}.${extension}`,
        }
    }
    return data;
}

export const getCityURL =  (city) => {
    // On remplace enlève les espaces de début et de fin 
    // On met tout en minuscule 
    // On enlève les accent 
    // On remplace les apostophes par des tiret
    // On remplace les espaces par des tirets
    var cityURL = city.trim().toLowerCase();
    cityURL = cityURL.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    cityURL = cityURL.replaceAll(/[^0-9a-zA-Z]+/gm, "-");
    return cityURL;
}

export const goToWebSite = async (data) => { 

    if (data.city) {
        const websiteURL = process.env.REACT_APP_WEBSITE_URL
        var city = getCityURL(data.city);
        var fullLink = `${websiteURL}/${city}`
        window.open(fullLink, "_blank")
    }
}
