import * as React from "react";
import { Edit, TabbedForm, FormTab, TextInput, ImageInput, required, BooleanInput, FormDataConsumer, Labeled } from 'react-admin';
import CustomImageField from "../../Components/CustomImageField";
import { goToWebSite, transform } from "./PrefectureUtil";
import { GenericToolbar, useArticleStyles } from "../GenericStyle";
import Button from '@material-ui/core/Button';

export const PrefectureEdit = (props) => {
    const classes = useArticleStyles(props);

    return (
        <Edit {...props} transform={transform}>
            <TabbedForm toolbar={<GenericToolbar />}>
                <FormTab label="Général">
                        <TextInput disabled label="Id" source="id" />
                        <TextInput source="city" label="Ville" validate={required()} />
                        <TextInput source="address" label="Addresse" validate={required()} />
                        <TextInput source="urlPrefecture" label="URL Préfecture" validate={required()} />
                        <BooleanInput source="isSubPrefecture" label="Est une sous préfecture" validate={required()}/>
                        <TextInput source="thumbAltTextImage" label="Alt Image préfecture" />
                        <ImageInput source="thumbImage" label="Image préfecture" multiple={false} accept="image/*" >
                            <CustomImageField source="thumbSrcImage" title="title" />
                        </ImageInput>
                        <FormDataConsumer>
                            {({ formData, dispatch, ...rest }) => {
                                if (!formData.thumbImage) {
                                    return (
                                        <div>
                                            <Labeled label="Original thumb image">
                                                <CustomImageField record={formData} source="thumbSrcImage" title="title" />
                                            </Labeled>
                                        </div>
                                    );
                                }
                            }}
                        </FormDataConsumer>
                </FormTab>
                <FormTab label="SEO">
                <FormDataConsumer>
                    {({ formData, dispatch, ...rest }) => {
                        return (
                            <div>
                                <Button variant="contained" className={classes.goButton} onClick={() => goToWebSite(formData)}>Voir sur le site</Button>
                            </div>
                        )
                    }}
                </FormDataConsumer>
                    <TextInput source="titleText" label="Titre de l'article" />
                    <TextInput source="article" label="Article" multiline={true} className={classes.article} fullWidth={true} />
                    <TextInput source="metaTitle" label="Meta Title" />
                    <TextInput source="metaDescription" label="Meta Description" formClassName={classes.description}/>
                    <TextInput source="articleAltTextImage" label="Alt Image article" />

                    <ImageInput source="articleImage" label="Image article" multiple={false} accept="image/*" >
                        <CustomImageField source="articleSrcImage" title="title" />
                    </ImageInput>
                    <FormDataConsumer>
                        {({ formData, dispatch, ...rest }) => {
                            if (!formData.articleImage) {
                                return (
                                    <div>
                                        <Labeled label="Original article image">
                                            <CustomImageField record={formData} source="articleSrcImage" title="title" />
                                        </Labeled>
                                    </div>
                                );
                            }
                        }}
                    </FormDataConsumer>
                </FormTab>
            </TabbedForm>
        </Edit>);
} 