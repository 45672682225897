import * as React from 'react';
import { createElement } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { MenuItemLink, getResources, usePermissions } from 'react-admin';
import { withRouter } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

const Menu = ({ onMenuClick, logout }) => {
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const permissions = usePermissions();
    const resources = useSelector(getResources);
    const resourcesOperator = resources.filter(r => !r.options?.superAdmin && !r.options?.seo);
    const resourcesSuperAdmin = resources.filter(r => r.options?.superAdmin);
    const ressourcesSEO = resources.filter(r => r.options?.seo);
    return (
        <div>
            {(permissions.permissions === "SuperAdmin" || permissions.permissions === "Admin") &&
                <>
                    <Typography style={{ marginLeft: "15px", marginTop: "20px", marginBottom: "20px", fontWeight: "bold" }}>Opérateurs</Typography>
                    {resourcesOperator.map(resource => (
                        <>
                            <MenuItemLink
                                key={resource.name}
                                to={`/${resource.name}`}
                                primaryText={resource.options && resource.options.label || resource.name}
                                onClick={onMenuClick}
                                sidebarIsOpen={open}
                                style={{ paddingLeft: "35px" }}
                            />
                            <Divider />
                        </>
                    ))
                    }
                </>
            }
            {permissions.permissions === "SEO" &&
                <>
                    <Typography style={{ marginLeft: "15px", marginTop: "20px", marginBottom: "20px", fontWeight: "bold" }}>SEO</Typography>
                    {ressourcesSEO.map(resource => (
                        <>
                            <MenuItemLink
                                key={resource.name}
                                to={`/${resource.name}`}
                                primaryText={resource.options && resource.options.label || resource.name}
                                onClick={onMenuClick}
                                sidebarIsOpen={open}
                                style={{ paddingLeft: "35px" }}
                            />
                            <Divider />
                        </>
                    ))}
                </>
            }
            {permissions.permissions === "SuperAdmin" &&
                <>
                    <div style={{ height: "30vh", width: "5px" }}></div>
                    <Typography style={{ marginLeft: "15px", marginTop: "20px", marginBottom: "20px", fontWeight: "bold" }}>Administrateurs</Typography>
                    <Divider />
                    {resourcesSuperAdmin.map(resource => (
                        <>
                            <MenuItemLink
                                key={resource.name}
                                to={`/${resource.name}`}
                                primaryText={resource.options && resource.options.label || resource.name}
                                onClick={onMenuClick}
                                sidebarIsOpen={open}
                                style={{ paddingLeft: "35px" }}
                            />
                            <Divider />
                        </>
                    ))}
                    <MenuItemLink
                        to="/subscriptions"
                        primaryText="Souscriptions"
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        style={{ paddingLeft: "35px" }}
                        key="subscriptions"
                    />
                    <Divider />
                    <MenuItemLink
                        to="/origins"
                        primaryText="Origines"
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        style={{ paddingLeft: "35px" }}
                        key="origins"
                    />
                     <Divider />
                    <MenuItemLink
                        to="/ltv"
                        primaryText="LTV"
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        style={{ paddingLeft: "35px" }}
                        key="ltv"
                    />
                </>
            }
            {isXSmall && logout}
        </div>
    );
}

export default withRouter(Menu);