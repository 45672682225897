import * as React from 'react';
import { Admin, Resource } from 'react-admin';
import authProvider from './Providers/AuthProvider';
import './App.css';

import jsonServerProvider from './Providers/DataProvider';
import frenchMessages from 'ra-language-french';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import { PrefecturesList } from './Pages/Prefectures/PrefecturesList';
import { PrefectureEdit } from './Pages/Prefectures/PrefectureEdit';
import { PrefectureCreate } from './Pages/Prefectures/PrefectureCreate';
import { ServicePrefecturesList } from './Pages/ServicePrefecture/ServicePrefectureList';
import { ServicePrefectureEdit } from './Pages/ServicePrefecture/ServicePrefectureEdit';
import { ServicePrefectureCreate } from './Pages/ServicePrefecture/ServicePrefectureCreate';
import { MotifPrefecturesList } from './Pages/MotifPrefecture/MotifPrefectureList';
import { MotifPrefectureEdit } from './Pages/MotifPrefecture/MotifPrefectureEdit';
import { MotifPrefectureCreate } from './Pages/MotifPrefecture/MotifPrefectureCreate';
import { URLToCrawlsList } from './Pages/URLToCrawl/URLToCrawlList';
import { URLToCrawlCreate } from './Pages/URLToCrawl/URLToCrawlCreate';
import { URLToCrawlEdit } from './Pages/URLToCrawl/URLToCrawlEdit';
import { AppointmentsList } from './Pages/Appointments/AppointmentList';
import { AppointmentCreate } from './Pages/Appointments/AppointmentCreate';
import { AppointmentEdit } from './Pages/Appointments/AppointmentEdit';
import { UsersList } from './Pages/Users/UserList';
import { UserCreate } from './Pages/Users/UserCreate';
import { UserEdit } from './Pages/Users/UserEdit';
import customRoutes from './customRoutes';
import Menu from './Components/Menu';

import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PersonIcon from '@material-ui/icons/Person';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Login from './Components/Login';
import { ClientsList } from './Pages/Clients/ClientList';
import { ClientShow } from './Pages/Clients/ClientShow';
import { AppointmentsToCheckList } from './Pages/AppointmentsToCheck/AppointmentsToCheckList';

const i18nProvider = polyglotI18nProvider(() => frenchMessages, 'fr');

const App = () => (
  <Admin loginPage={Login} i18nProvider={i18nProvider} authProvider={authProvider} dataProvider={jsonServerProvider} customRoutes={customRoutes} menu={Menu}>
    {permissions => [
      (permissions === 'Admin' || permissions === 'SuperAdmin') && <Resource options={{ label: 'Clients' }} name="Admin" list={ClientsList} show={ClientShow} />,
      (permissions === 'Admin' || permissions === 'SuperAdmin') && <Resource options={{ label: 'RDV à Checker' }} name="Appointments/ToCheck" list={AppointmentsToCheckList} />,
      <Resource options={{ label: 'Prefectures', superAdmin: true, seo: true }} name="Prefectures" list={PrefecturesList} create={PrefectureCreate} edit={PrefectureEdit} icon={AccountBalanceIcon} />,
      (permissions === 'Admin' || permissions === 'SuperAdmin' || permissions === 'SEO') && <Resource options={{ label: 'Services Préfectures', superAdmin: true }} name="ServicePrefectures" list={ServicePrefecturesList} create={ServicePrefectureCreate} edit={ServicePrefectureEdit} />,
      (permissions === 'Admin' || permissions === 'SuperAdmin' || permissions === 'SEO') && <Resource options={{ label: 'Motifs Préfectures', superAdmin: true }} name="MotifPrefectures" list={MotifPrefecturesList} create={MotifPrefectureCreate} edit={MotifPrefectureEdit} icon={AssignmentIcon} />,
      (permissions === 'SuperAdmin' || permissions === 'SEO') && < Resource options = {{ label: 'Rendez Vous', superAdmin: true, seo: true }} name="Appointments" list={AppointmentsList} create={AppointmentCreate} edit={AppointmentEdit} icon={AccessTimeIcon} />,
      permissions === 'SuperAdmin' && <Resource options={{ label: 'Utilisateurs', superAdmin: true }} name="AdminUser" list={UsersList} create={UserCreate} edit={UserEdit} icon={PersonIcon} />
    ]}
  </Admin>
)

export default App;
