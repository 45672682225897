import * as React from "react";
import { Create, TabbedForm, FormTab, TextInput, ImageInput, ImageField, required, BooleanInput, FormDataConsumer } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { goToWebSite, transform } from "./PrefectureUtil";
import { GenericToolbar, useArticleStyles } from "../GenericStyle";
import Button from '@material-ui/core/Button';

export const PrefectureCreate = (props) => {
    const classes = useArticleStyles(props);

    return (
        <Create {...props} transform={transform}>
            <TabbedForm toolbar={<GenericToolbar />}>
                <FormTab label="Général">
                    <TextInput disabled label="Id" source="id" />
                    <TextInput source="city" label="Ville" validate={required()} />
                    <TextInput source="address" label="Addresse" validate={required()} />
                    <TextInput source="urlPrefecture" label="URL Préfecture" validate={required()} />
                    <BooleanInput source="isSubPrefecture" label="Est une sous préfecture" validate={required()} defaultValue={false}  />
                    <TextInput source="thumbAltTextImage" label="Alt Image préfecture" />
                    <ImageInput source="thumbImage" label="Image article" multiple={false} accept="image/*">
                        <ImageField source="src" title="title" />
                    </ImageInput>
                </FormTab>
                <FormTab label="SEO">
                <FormDataConsumer>
                    {({ formData, dispatch, ...rest }) => {
                        return (
                            <div>
                                <Button variant="contained" className={classes.goButton} onClick={() => goToWebSite(formData)}>Voir sur le site</Button>
                            </div>
                        )
                    }}
                </FormDataConsumer>
                    <TextInput source="titleText" label="Titre de l'article" />
                    <TextInput source="article" label="Article" multiline={true} className={classes.article} fullWidth={true} />
                    <TextInput source="metaTitle" label="Meta Title" />
                    <TextInput source="metaDescription" label="Meta Description" formClassName={classes.description}/>
                    <TextInput source="articleAltTextImage" label="Alt Image article" />
                    <ImageInput source="articleImage" label="Image article" multiple={false} accept="image/*" >
                        <ImageField source="src" title="title" />
                    </ImageInput>
                </FormTab>
            </TabbedForm>
        </Create>
    );

}