import { makeStyles } from '@material-ui/core/styles';
import { Toolbar, SaveButton } from 'react-admin';

export const useArticleStyles = makeStyles(
    {
        article: {
            '& .MuiInputBase-root': {
                minHeight: '400px'

            },
            '& .MuiInputBase-input': {
                minHeight: '360px !important'

            }
        },
        description: {
            '& .MuiFormControl-root': {
                width: '700px'
            }
        },
        goButton: {
            position: 'absolute',
            right: '40px'
        }
    }
);

export const GenericToolbar = props => (
    <Toolbar {...props} >
        <SaveButton
            label="Enregistrer"
            redirect={false}
            submitOnEnter={false}
        />
        <SaveButton
            label="Enregistrer et quitter"
            redirect="list"
            submitOnEnter={false}
            variant="text"
        />
    </Toolbar>
);