import * as React from "react";
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { useRecordContext } from 'react-admin';

const CustomStatusField = (props) => {
    const { source } = props;
    const record = useRecordContext(props);

    const getColor = (status) => {
        switch (status) {
            case 'trialing':
                return "orange";
            case 'active':
                return "green";
            case 'latePayment':
                return "blue";
            case 'canceled by operator':
                return "red";
            case 'canceled by user':
                return "red";
            case 'cancelByLatePayment':
                return "red";
            case 'review':
                return "pink";
        }
    }

    return <Box sx={{ backgroundColor: getColor(record?.subscription ? record?.subscription?.status : "review"), width: "100px", color: "white", textAlign: "center", padding: "5px 0px", borderRadius: "5px" }}>{record?.subscription ? record?.subscription?.status : "review"}</Box>;
}

CustomStatusField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default CustomStatusField;