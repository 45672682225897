import * as React from "react";
import { Create, SimpleForm, TextInput, required } from 'react-admin';

export const ServicePrefectureCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput disabled label="Identifiant" source="id" />
            <TextInput label="Nom" source="name" validate={required()} />
            <TextInput label="Description" source="description" />
        </SimpleForm>
    </Create>
);