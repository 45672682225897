
import { convertFileToBase64 } from "../../Providers/DataProvider";
import { getCityURL } from "../Prefectures/PrefectureUtil";

export const transform = async (data) => {
    if (data.articleImage) {
        var base64Picture = await convertFileToBase64(data.articleImage)
        data.articleImage = {
            src: base64Picture,
            filename: `${data.articleImage.title}`,
        }
    }
    return data;
}

export const goToWebSite = async (data, dataProvider) => {
    var prefecture = data.prefecture;
    var servicePrefecture = data.servicePrefecture;
    var motifPrefecture = data.motifPrefecture;
    
    if (dataProvider && data?.prefecture && data?.servicePrefecture && data?.motifPrefecture) {
        var prefRes = await dataProvider.getOne("Prefectures", prefecture);
        prefecture = prefRes.data;
        var serviceRes = await dataProvider.getOne("ServicePrefectures", servicePrefecture);
        servicePrefecture = serviceRes.data;
        var motifRes = await dataProvider.getOne("MotifPrefectures", motifPrefecture);
        motifPrefecture = motifRes.data;
    }

    if (data?.prefecture && data?.servicePrefecture && data?.motifPrefecture) {
        const websiteURL = process.env.REACT_APP_WEBSITE_URL
        var city = getCityURL(prefecture.city);
        var service = getCityURL(servicePrefecture.name);
        var motif = getCityURL(motifPrefecture.name);
        var fullLink = `${websiteURL}/${city}/${service}/${motif}`
        window.open(fullLink, "_blank")
    }
}