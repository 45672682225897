import React, { useEffect, useState } from 'react';
import { CartesianGrid, XAxis, YAxis, Tooltip, BarChart, LineChart, Bar, Line, Legend, Label, ResponsiveContainer } from 'recharts';
import { useDataProvider } from 'react-admin';


const LTVGraph = () => {

    const dataProvider = useDataProvider();
    const [value, setValue] = useState('');
    const [graphData, setGraphData] = useState([]);

    useEffect(() => {
        refreshGraph();
    }, [value]);

    const refreshGraph = async () => {
        const subscriptionPromise = await dataProvider.getCustom('Admin/LTV/Stats');
        setGraphData(subscriptionPromise.data);
    };

    return (
        <>
            <div style={{ width: "100%", height: "100%" }}>
                <ResponsiveContainer width='100%' height='80%' style={{ top: "15px" }}>
                    <LineChart data={graphData} margin={{ top: 15, right: 30, left: 20, bottom: 20 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" >
                            <Label value="Date" offset={0} position="bottom" />
                        </XAxis>
                        <YAxis >
                            <Label value="LTV" angle="-90" position="insideLeft" offset={20} startOffset={20} />
                        </YAxis>
                        <Tooltip />
                        <Legend verticalAlign="top" height={36} />
                        <Line name="LTV" type="monotone" dataKey="value" stroke="#02DD03" />
                    </LineChart >
                </ResponsiveContainer>
            </div>
        </>
    );
}

export default LTVGraph